import { Flex, Input, Select, Text } from '@chakra-ui/react';
import { FormField, SubmitButton } from '@v10-codeparva/gatsby-theme-v10-tfw/src/components/form';
import React, { useState } from 'react';
import { ReserveUnitFormPropTypes } from '@v10-codeparva/gatsby-theme-v10-tfw/src/components/reserveUnitForm/ReserveUnitFormPropTypes';
import { localisable } from '../../generic/localisable';
import { CallToActionButton } from '@v10-codeparva/gatsby-theme-v10-tfw/src/components';
import GlobalConstants from '@v10-codeparva/gatsby-theme-v10-tfw/src/constants/GlobalConstants';
import {
  americanStates,
  canadianProvince,
  fieldFlexStyle,
  militaryBranches,
  militaryTypes,
} from '@v10-codeparva/gatsby-theme-v10-tfw/src/components/reserveUnitForm/constant';
import { getMoveInCharges } from '@v10-codeparva/v10-tfw-revamp-package-api';

export const ReserveUnitFormBody = ({
  formConfig,
  showAltDetails,
  showMilitaryDetails,
  ctaButtonConfig,
  currentFormProps,
  onSubmitForm,
  lstRentalPeriods,
  lstInsuranceChoices,
  lstUnitTypes,
  rentalCharges,
  setRentalCharges,
  isReserve,
  selectedUnitType,
  setSelectedUnitType,
  selectedRentalPeriod,
  setSelectedRentalPeriod,
  retrievedFormData,
  premium,
  setPremium,
  reservationFee,
  monthlyRate,
}: ReserveUnitFormPropTypes.IReserveFormBodyProps) => {
  const [selectedInsuranceChoice, setSelectedInsuranceChoice] = useState<any>();
  const [shouldDisableBtn, setShouldDisableBtn] = useState(false);

  const getTodaysDate = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1; // Months are zero-based
    const year = today.getFullYear();

    return `${month}-${day}-${year}`;
  };

  const onChangeofField = async (unitType: number, insuranceId: number, rentalPeriod: number) => {
    if (!isReserve) {
      setShouldDisableBtn(true);
      const requestBody = {
        dteStartDate: getTodaysDate(),
        intInsuranceID: insuranceId,
        intPeriodID: rentalPeriod,
        intUnitTypeID: unitType,
        cid: process.env.CID || '',
      };
      const rentalCharges = await getMoveInCharges(requestBody);
      const unitChargesData = {
        rate: rentalCharges?.objCharges.Rate,
        proRate: rentalCharges?.objCharges.ProrateAmt,
        setUpCost: rentalCharges?.objCharges.Setup,
        totalAmount: rentalCharges?.objCharges.TotalChargesAmount,
      };
      setRentalCharges(unitChargesData);
      setShouldDisableBtn(false);
    }
  };

  const getPremium = (insuranceChoice: number) => {
    const premiumValue =
      !!lstInsuranceChoices.length &&
      lstInsuranceChoices.reduce((acc: any, value: any) => {
        if (Number(value.InsuranceChoiceID) === insuranceChoice) {
          return value.Premium;
        }
        return acc;
      }, '');
    setPremium(premiumValue);
  };

  const { isDarkTheme } = GlobalConstants.getInstance().getConstants();
  const dropDownStyle = {
    style: { backgroundColor: `${isDarkTheme ? 'black' : 'white'}` },
  };
  const {
    firstName,
    lastName,
    phone,
    email,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    driverLicenseNumber,
    driverLicenseExpiration,
    birthDate,
    militaryType,
    militaryBranch,
    militaryID,
    militaryDeployedDate,
    militaryDivision,
    militaryCommandingOfficer,
    militaryCommandingOfficerNumber,
    altFirstName,
    altLastName,
    altPhone,
    altEmail,
    altAddressLine1,
    altAddressLine2,
    altCity,
    altState,
    altZipCode,
    unitType,
    insuranceType,
    period,
    moveInDate,
  } = formConfig;
  const { form } = currentFormProps || {};

  const formButtons = () => {
    return (
      <Flex
        direction={{ base: 'column', sm: 'row' }}
        gap="16px"
        justifyContent={{ base: 'center', sm: 'flex-end' }}
        alignItems="center"
        maxW={{ sm: '650px', md: '930px' }}
      >
        <CallToActionButton
          {...ctaButtonConfig}
          otherButtonProps={{ w: { base: '100%', sm: '294px' }, height: '36px' }}
        />
        <SubmitButton
          w={{ base: '100%', sm: '294px' }}
          h={'36px'}
          label="Next"
          type="submit"
          formProps={!!retrievedFormData ? { ...currentFormProps, dirty: true } : currentFormProps}
          isDisabled={shouldDisableBtn}
        />
      </Flex>
    );
  };
  const stateOptions = () => {
    return (
      <>
        <optgroup label="U.S. States/Territories" {...dropDownStyle}>
          {!!americanStates.length &&
            americanStates.map((type: any) => (
              <option key={type.id} value={type.id}>
                {type.description}
              </option>
            ))}
        </optgroup>
        <optgroup label="Canadian Provinces" {...dropDownStyle}>
          {!!canadianProvince.length &&
            canadianProvince.map((type: any) => (
              <option key={type.id} value={type.id}>
                {type.description}
              </option>
            ))}
        </optgroup>
      </>
    );
  };
  const personalDetails = () => {
    return (
      <>
        <Text
          textStyle="h2"
          textAlign="left"
          borderBottom={'solid 1px'}
          paddingBottom={'16px '}
          maxW={{ sm: '650px', md: '930px' }}
        >
          {localisable.reserveFormTitle}
        </Text>
        <Flex {...fieldFlexStyle} direction={{ base: 'column', md: 'row' }}>
          <FormField {...firstName}>
            <Input />
          </FormField>
          <FormField {...lastName}>
            <Input />
          </FormField>
        </Flex>
        <Flex {...fieldFlexStyle}>
          <FormField {...phone}>
            <Input />
          </FormField>
          <FormField {...email}>
            <Input />
          </FormField>
        </Flex>
        <Flex {...fieldFlexStyle}>
          <FormField {...addressLine1}>
            <Input />
          </FormField>
        </Flex>
        <Flex {...fieldFlexStyle}>
          <FormField {...addressLine2}>
            <Input />
          </FormField>
        </Flex>
        <Flex {...fieldFlexStyle} w={{ sm: '650px', md: '930px' }} wrap="wrap">
          <FormField {...city}>
            <Input />
          </FormField>
          <FormField {...state}>
            <Select>{stateOptions()}</Select>
          </FormField>
          <FormField {...zipCode}>
            <Input />
          </FormField>
        </Flex>
        <Flex {...fieldFlexStyle} w={{ sm: '650px', md: '930px' }} wrap="wrap">
          <FormField {...driverLicenseNumber}>
            <Input />
          </FormField>
          <FormField {...driverLicenseExpiration}>
            <Input />
          </FormField>
          <FormField {...birthDate}>
            <Input />
          </FormField>
        </Flex>
      </>
    );
  };
  const militaryDetails = () => {
    return (
      <>
        <Flex {...fieldFlexStyle} w={{ sm: '650px', md: '930px' }} wrap="wrap">
          <FormField {...militaryType}>
            <Select>
              {!!militaryTypes.length &&
                militaryTypes.map((type: any) => (
                  <option key={type.value} value={type.value} {...dropDownStyle}>
                    {type.description}
                  </option>
                ))}
            </Select>
          </FormField>
          <FormField {...militaryBranch}>
            <Select>
              {!!militaryBranches.length &&
                militaryBranches.map((type: any) => (
                  <option key={type.value} value={type.value} {...dropDownStyle}>
                    {type.description}
                  </option>
                ))}
            </Select>
          </FormField>
          <FormField {...militaryID}>
            <Input />
          </FormField>
        </Flex>
        <Flex {...fieldFlexStyle} w={{ sm: '650px', md: '930px' }} wrap="wrap">
          <FormField {...militaryDeployedDate}>
            <Input />
          </FormField>
          <FormField {...militaryDivision}>
            <Input />
          </FormField>
          <FormField {...militaryCommandingOfficer}>
            <Input />
          </FormField>
          <FormField {...militaryCommandingOfficerNumber}>
            <Input />
          </FormField>
        </Flex>
      </>
    );
  };
  const altDetails = () => {
    return (
      <>
        <Flex direction="column" gap="24px">
          <Text
            textStyle="h2"
            textAlign="left"
            borderBottom={'solid 1px'}
            paddingBottom={'16px '}
            maxW={{ sm: '650px', md: '930px' }}
          >
            {localisable.alternateSectionTitle}
          </Text>
          <Flex direction={{ base: 'column', sm: 'row' }} gap="24px">
            <FormField {...altFirstName}>
              <Input />
            </FormField>
            <FormField {...altLastName}>
              <Input />
            </FormField>
          </Flex>
          <Flex direction={{ base: 'column', sm: 'row' }} gap="24px">
            <FormField {...altPhone}>
              <Input />
            </FormField>
            <FormField {...altEmail}>
              <Input />
            </FormField>
          </Flex>
          <Flex>
            <FormField {...altAddressLine1}>
              <Input />
            </FormField>
          </Flex>
          <Flex>
            <FormField {...altAddressLine2}>
              <Input />
            </FormField>
          </Flex>
          <Flex {...fieldFlexStyle} w={{ sm: '650px', md: '930px' }} wrap="wrap">
            <FormField {...altCity}>
              <Input />
            </FormField>
            <FormField {...altState}>
              <Select>{stateOptions()}</Select>
            </FormField>
            <FormField {...altZipCode}>
              <Input />
            </FormField>
          </Flex>
        </Flex>
      </>
    );
  };
  const unitDetails = () => {
    return (
      <Flex direction="column" gap="24px">
        <Text
          textStyle="h2"
          textAlign="left"
          borderBottom={'solid 1px'}
          paddingBottom={'16px '}
          maxW={{ sm: '650px', md: '930px' }}
        >
          {localisable.unitSectionTitle}
        </Text>
        <Flex {...fieldFlexStyle} w={{ sm: '650px', md: '930px' }} wrap="wrap">
          <FormField {...unitType}>
            <Select
              value={selectedUnitType}
              onChangeCapture={async (e: any) => {
                setSelectedUnitType(Number(e.target.value));
                await onChangeofField(
                  Number(e.target.value),
                  selectedInsuranceChoice,
                  selectedRentalPeriod,
                );
              }}
            >
              {!!lstUnitTypes.length &&
                lstUnitTypes.map((unit: any) => {
                  return (
                    unit.IsUnitsAvailable && (
                      <option key={unit.UnitTypeID} value={unit.UnitTypeID} {...dropDownStyle}>
                        {unit.Description}
                      </option>
                    )
                  );
                })}
            </Select>
          </FormField>
          {!isReserve ? (
            // <FormField {...insuranceType}>
            //   <Select
            //     value={selectedInsuranceChoice}
            //     onChangeCapture={async (e: any) => {
            //       setSelectedInsuranceChoice(Number(e.target.value));
            //       getPremium(Number(e.target.value));
            //       await onChangeofField(
            //         selectedUnitType,
            //         Number(e.target.value),
            //         selectedRentalPeriod,
            //       );
            //     }}
            //   >
            //     {!!lstInsuranceChoices.length &&
            //       lstInsuranceChoices.map((insurance: any) => {
            //         return (
            //           <option
            //             key={insurance.InsuranceChoiceID}
            //             value={insurance.InsuranceChoiceID}
            //             {...dropDownStyle}
            //           >
            //             {insurance.CoverageDescription}
            //           </option>
            //         );
            //       })}
            //   </Select>
            // </FormField>
            <Flex width={'40%'} alignItems={'center'}>
              <b>
                If you are interested in obtaining insurance coverage for your unit, please contact
                the office.
              </b>
            </Flex>
          ) : (
            <FormField {...moveInDate}>
              <Input placeholder="DD-MM-YYYY" />
            </FormField>
          )}
        </Flex>
        {!isReserve && (
          <Flex
            direction={{ base: 'column', md: 'row' }}
            gap="24px"
            width={{ base: '100%', sm: '49%' }}
          >
            <FormField {...period}>
              <Select
                value={selectedRentalPeriod}
                onChangeCapture={async (e: any) => {
                  setSelectedRentalPeriod(Number(e.target.value));
                  await onChangeofField(
                    selectedUnitType,
                    selectedInsuranceChoice,
                    Number(e.target.value),
                  );
                }}
              >
                {!!lstRentalPeriods.length &&
                  lstRentalPeriods.map(({ PeriodID, PeriodDescription }) => (
                    <option key={PeriodID} value={PeriodID} {...dropDownStyle}>
                      {PeriodDescription}
                    </option>
                  ))}
              </Select>
            </FormField>
          </Flex>
        )}
      </Flex>
    );
  };
  const rateSection = () => {
    return (
      <Flex direction={'column'} gap="16px">
        <Text>{`${localisable.rate} : $${!isReserve ? rentalCharges?.rate : monthlyRate}`}</Text>
        {isReserve && !!reservationFee && (
          <Text>{`${localisable.reservationCharges} : $${reservationFee}`}</Text>
        )}
        {!isReserve && <Text>{`${localisable.proRate} : $${rentalCharges?.proRate}`}</Text>}
        {!isReserve && <Text>{`${localisable.setUpCost} : $${rentalCharges?.setUpCost}`}</Text>}
        {!isReserve && !!(selectedInsuranceChoice || premium) && (
          <Text>{`${localisable.premium} : $${premium}`}</Text>
        )}
      </Flex>
    );
  };

  return (
    <>
      <Flex direction="column" gap="24px">
        {personalDetails()}
        {showMilitaryDetails && militaryDetails()}
        {showAltDetails && altDetails()}
        {unitDetails()}
        {rateSection()}
        {formButtons()}
      </Flex>
    </>
  );
};
