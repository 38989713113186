import * as React from 'react';
import { Flex, Text } from "@chakra-ui/react";
import { ContactUsPropTypes } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/contactUs/ContactUsPropTypes";
import { convertToTime } from "@v10-codeparva/gatsby-theme-v10-tfw/src/utils/mapEnum";
import { localisable } from "../../generic/localisable";
import { phoneNumberFormat } from '@v10-codeparva/gatsby-theme-v10-tfw/src/utils/constant';

export const RenderFacilityDetails = ({ iFrameUrl, currentLocation, variant }: ContactUsPropTypes.TRenderFacilityDetailsProps) => {

    const { contactDetails = [], officeHours = [],
        storageAccessHours: { is24Hours, type: sahDescription, openingTime: sahOpen, closingTime: sahClose }
    } = currentLocation;

    const renderGoogleMap = () => {
        return <iframe
            title="Google Map"
            width='-webkit-fill-available'
            height="312px"
            style={{ border: 0 }}
            src={iFrameUrl}
            allowFullScreen
        />
    };

    const renderContactDetails = () => {
        return <Flex direction='column' gap={variant === 'appointment variant' ? '0px' : '24px'}>
            {!!contactDetails.length && contactDetails.map(({ type, data, redirect }: any, index) => {
                if (type.toLocaleLowerCase().includes('phone')) {
                    data = phoneNumberFormat(data);
                }
                return <Flex key={index} direction={variant === 'appointment variant' ? 'row' : 'column'}>
                    <Text textStyle='subtitle'>{type}:</Text>
                    <Text textStyle='body1'>
                        <a href={redirect}>{data}</a>
                    </Text>
                </Flex>
            })}
            <Flex key={5} direction={variant === 'appointment variant' ? 'row' : 'column'}>
                <Text textStyle='subtitle'>{localisable.afterHours}</Text>
                <Text textStyle='body1'>
                    <a href={`tel:5186106743`}>{localisable.afterHoursPhoneNumber}</a>
                </Text>
            </Flex>
        </Flex>
    };

    const renderOfficeHours = () => {
        switch (variant) {
            case 'default variant':
            case 'regular variant':
                return <Flex direction='column' gap='24px'>
                    {!!officeHours.length && officeHours.map((value: any) => {
                        return <Flex direction='column'>
                            <Text textStyle='subtitle'>{value.type}:</Text>
                            <Text textStyle='body1'>{!value.isClosed ? `${convertToTime(value.openingTime)}-${convertToTime(value.closingTime)}` : localisable.closed.toUpperCase()}</Text>
                        </Flex>
                    })}
                    <Flex direction='column'>
                        <Text textStyle='subtitle'>{localisable.weekends}</Text>
                        <Text textStyle='body1'>{localisable.appointmentOnly}</Text>
                    </Flex>
                </Flex>
                break;
            case 'appointment variant':
                return <Flex direction='column'>
                    <Text textStyle='subtitle'>Hours:</Text>
                    <Text textStyle='subtitle'>NO OFFICE, ALL BUSINESS WILL BE CONDUCTED REMOTELY OR BY APPOINTMENT</Text>
                    <Flex direction='row'>
                        <Text textStyle='subtitle'>Office Hours:</Text>
                        <Text textStyle='body1'>By Appointment Only</Text>
                    </Flex>
                </Flex>
                break;
        };
    };

    const renderStorageHours = () => {
        switch (variant) {
            case 'default variant':
                return <Flex direction='column'>
                    <Text textStyle='subtitle'>{sahDescription}:</Text>
                    <Text textStyle='body1'>{is24Hours ? localisable.is24HoursOpen : `${convertToTime(sahOpen)}-${convertToTime(sahClose)}`}</Text>
                </Flex>
                break;
            case 'appointment variant':
            case 'regular variant':
                return <Flex direction='row'>
                    <Text textStyle='subtitle'>Access Hours:</Text>
                    <Text textStyle='body1'>{localisable.accessHours}</Text>
                </Flex>
        }
    }

    return (
        <Flex direction='column' gap='24px' w='100%'>
            {renderGoogleMap()}
            {renderContactDetails()}
            <Flex direction='column' gap={variant === 'appointment variant' ? '0px' : '24px'}>
                {renderOfficeHours()}
                {renderStorageHours()}
            </Flex>
        </Flex>
    )
}
