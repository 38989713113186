import { localisable as originalLocalisable } from "@v10-codeparva/gatsby-theme-v10-tfw/src/generic/localisable";

export const localisable = {
    ...originalLocalisable, 
    passwordExp: 'Tenant Full Phone Number',
    exampleLine1: `John Smith's Phone Number is 1234567890`,
    exampleLine3: `John's Password: 1234567890`,
    accessHours: '24 hours a day, 7 days a week',
    weekends: 'Saturday - Sunday:',
    appointmentOnly: 'By Appointment Only',
    afterHours: 'After Hours :',
    afterHoursPhoneNumber: '(518) 610-6743',
    primaryPhoneNumber : 'Primary Phone Number',
};
